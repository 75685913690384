import axios from 'axios'
import { getToken, checkTokenIsset, goLogin } from "@/libs/login.js"
import { Message } from 'element-ui';
const instance = axios.create({
    baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_DOMAIN : "/",
    // async:true,
    // crossDomain:true,
  })

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // let token = getToken();
    // token存在把token传给后端
    // if(checkTokenIsset()){
    //   config.headers.authorization = token;
    // }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // 这个应该是登录失效的，拦截并跳登录
    if(response.data.code == -99 && response.config?.checkLogin !== false){
      Message.error({message: response.data.msg, onClose:()=>{
        goLogin();
      }});
    }else{
      return response.data;
    }
}, function (error) {
    //502后端的错误码
    if(error.response.status == 502){
      // 这个应该是登录失效的，拦截并跳登录
      // if(error.response?.data?.code == -99){
      //   Message.error({message: error.response.data.msg, onClose:()=>{
      //     goLogin();
      //   }});
      // }else{
        return error.response.data;
      // }
    }else{
      // 超出 2xx 范围的状态码都会触发该函数。
      // 对响应错误做点什么
      return Promise.reject(error);
    }
});

export default instance