import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import scroll from 'vue-seamless-scroll';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
Vue.use(scroll);
Vue.use(ElementUI);

//px按照屏幕缩放
Vue.prototype.$adjustSize=function(num){
    let docEl = document.documentElement,
    clientWidth = window.innerWidth || 
    document.documentElement.clientWidth ||
    document.body.clientWidth;
    if(!clientWidth) return ;
    let adjustSize = (clientWidth /  1920);
    return num * adjustSize;
}
//px转vw
Vue.prototype.$px2vw = function(num){
  return (num / 19.2).toFixed(6);
}
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
