import Vue from "vue";
import VueRouter from "vue-router";
import { goLogin, checkTokenIsset, codeLogin, checkLogin } from "@/libs/login.js"; 
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/mainIndex.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const LOGIN_PAGE_NAME = "login";
router.beforeEach((to, from, next) => {
  // 带code则认为是三方登录带过来的
  if(to.query?.code){
    codeLogin({
      code: to.query.code,
      success:()=>{
        next();
      }
    })
  }else if(to.name != LOGIN_PAGE_NAME){ // 非登录页
    if(checkTokenIsset()){  //token存在直接进去
      next();
    }else{  //不存在跳登录
      checkLogin(()=>{  //校验登录
        next();
      })
    }
  }else{  //登录页，直接进去
    next();
  }
})

export default router;
