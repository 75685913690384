import Cookies from "js-cookie";
import router from "../router";
import { login, logout as logoutApi } from "@/api/index.js";
import { Loading, Message } from 'element-ui';
import store from "../store";
function getCookieKey(){
  return 'token';
}
/**
 * 设置登录的token
 * @param {*} token 
 * @returns 
 */
export function setToken(token) {
  // Cookies.set(getCookieKey(), token);
  store.commit('user/setToken', token);
}

/**
 * 获取登录token
 * @returns 
 */
export function getToken(){
  // let cookieToken = Cookies.get(getCookieKey());
  // if(store.state.user.token == null && cookieToken != null){
  //   store.commit('user/setToken', cookieToken);
  // }
  return store.state.user.token;
}

/**
 * 校验token是否存在
 * @returns 
 */
export function checkTokenIsset(){
  let token = getToken();
  if(token != '' && token != null && token != undefined){
    return true;
  }else{
    return false;
  }
}

/**
 * 退出
 */
export function logout(){
  // Cookies.remove(getCookieKey());
  store.state.user.token = null;
}

/**
 * 三方带code登录
 */
export function codeLogin({code, success}){
  const loading = Loading.service({
    lock: true,
    text: '登录中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });

  login({code: code}).then(res=>{
    if(res.code == 0){
      store.commit('user/setToken', res.data);
      if(success != undefined || success != null){
        success();
      }else{
        router.push('/');
      }
    }else{
      Message.error({message: res.msg, onClose:()=>{
        goLogin();
      }});
    }
    loading.close();
  })
}

/**
 * 校验登录
 * @param {*} success 回调函数 
 */
export function checkLogin(success){
  const loading = Loading.service({
    lock: true,
    text: '加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  login({}).then(res=>{
    if(res.code == 0){
      store.commit('user/setToken', 'ok');
      if(success != undefined || success != null){
        success();
      }else{
        router.push('/');
      }
    }else{
      goLogin();
    }
    loading.close();
  })
}

// 跳登录
export function goLogin(){
  logoutApi({}).then(res=>{
    // if(res.code == 0){
      logout();
      router.push('/login')
    // }else{
    //   Message.error({message: res.msg});
    // }
  })
  
}