import axios from '@/libs/axios';
import axios2 from '@/libs/axios2';

// //总数（产品、工厂、采购商、曝光、浏览产品）
// export const getTotalData = (data) => {
//     return axios.request({
//         url: 'admin/totaldata',
//         data: data,
//         method: 'post'
//     });
// };
// // 总数（浏览产品数）
// export const getTotalToyBrowse = (data) => {
//     return axios.request({
//         url: 'admin/totaltoybrowse',
//         data: data,
//         method: 'post'
//     });
// };

// // 排行区（关键词排名）
// export const getListKeyword = (data) => {
//     return axios.request({
//         url: 'admin/listkeyword',
//         data: data,
//         method: 'post'
//     });
// };

// // 排行区（分类排名）
// export const getListCategory = (data) => {
//     return axios.request({
//         url: 'admin/listcategory',
//         data: data,
//         method: 'post'
//     });
// };

// // 排行区（工厂排名）
// export const getListFactory = (data) => {
//     return axios.request({
//         url: 'admin/listfactory',
//         data: data,
//         method: 'post'
//     });
// };

// // 排行区（产品排名）
// export const getListToy = (data) => {
//     return axios.request({
//         url: 'admin/listtoy',
//         data: data,
//         method: 'post'
//     });
// };


// // 实时区(在线工厂)
// export const getFactoryRealtime = (data) => {
//     return axios.request({
//         url: 'admin/factoryrealtime',
//         data: data,
//         method: 'post'
//     });
// };

// //实时区（被选中产品）
// export const getToyRealtime = (data) => {
//     return axios.request({
//         url: 'admin/toyrealtime',
//         data: data,
//         method: 'post'
//     });
// };

// //实时区（在线公司）
// export const getCompanyRealtime = (data) => {
//     return axios.request({
//         url: 'admin/companyrealtime',
//         data: data,
//         method: 'post'
//     });
// };

// //访客大地图
// export const getListaccess = (data) => {
//     return axios.request({
//         url: 'admin/listaccess',
//         data: data,
//         method: 'post'
//     });
// };

//顶部总数
export const getTotal = (data) => {
    return axios.request({
        url: 'api/statistics/get_total',
        params: data,
        method: 'get'
    });
};

//访客数 type 1 国内 2 全球
export const getVisitorChart = (data) => {
  return axios.request({
      url: 'api/statistics/get_visitor_chart',
      params: data,
      method: 'get'
  });
};

//排行区 time 1 一周内 2 一月内 3 一年内
export const getCharts = (data) => {
  return axios.request({
      url: 'api/statistics/get_charts',
      params: data,
      method: 'get'
  });
};

//实时区
export const getRealtimeToy  = (data) => {
  return axios.request({
      url: 'api/statistics/get_realtime_toy',
      params: data,
      method: 'get'
  });
};

// 登录
// export const login = (data) => {
//   return axios2.request({
//     url: 'web/Login/login',
//     params: data,
//     method: 'get'
//   });
// }


// 登录
export const login = (data) => {
  return axios.request({
    url: 'api/statistics/login',
    params: data,
    method: 'get'
  });
}

// 退出
export const logout = (data) => {
  return axios.request({
    url: 'api/statistics/logout',
    params: data,
    method: 'get',
    checkLogin: false,
  });
}